import React, { useEffect } from "react";
import { FLORA_SHOPIFY } from "../../assets/Links";

const Tos = () => {
  useEffect(() => {
    window.location.replace(`${FLORA_SHOPIFY}/pages/tos/`);
  }, []);

  return <div></div>;
};

export default Tos;
